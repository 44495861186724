.dashboard-stats .card-category {
    font-size: 0.7rem !important;
}

.job-stats .react-select {
    justify-content: right;
    margin-top: 2px;
    margin-right: 8px;

    .react-select__control .react-select__value-container {
        width: 61px !important;
    }

}


.badge {
    color: #fff !important;
  &.cancelled {
    color: #fd5d93 !important;
  }

  &.posted {
    color: #1d8cf8 !important;
  }

  &.accepted {
    color: #ffff80 !important;
  }

  &.completed {
    color: #00bf9a !important;
  }
  }

  .status-badge-block {
    margin-top: -20px;
  }