/* --------------------------------

Nucleo Outline Web Font - nucleoapp.com/
License - nucleoapp.com/license/
Created using IcoMoon - icomoon.io

-------------------------------- */

@font-face {
  font-family: 'Nucleo';
  src: url('../fonts/nucleo.eot');
  src: url('../fonts/nucleo.eot') format('embedded-opentype'), url('../fonts/nucleo.woff2') format('woff2'), url('../fonts/nucleo.woff') format('woff'), url('../fonts/nucleo.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/*------------------------
	base class definition
-------------------------*/

.tim-icons {
  display: inline-block;
  font: normal normal normal 1em/1 'Nucleo',sans-serif;
  vertical-align: middle;
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-icon-detail {
  text-align: center;
  padding: 45px 0 30px;
  border: 1px solid #e44cc4;
  border-radius: .1875rem;
  margin: 15px 0;
  min-height: 168px;
}

.font-icon-detail i {
  color: #FFFFFF;
  font-size: 1.5em;
}

.font-icon-detail p {
  color: #e44cc4 !important;
  margin-top: 30px;
  padding: 0 10px;
  font-size: .7142em;
}

/*------------------------
  change icon size
-------------------------*/

.tim-icons-sm {
  font-size: 0.8em;
}

.tim-icons-lg {
  font-size: 1.2em;
}

/* absolute units */

.tim-icons-16 {
  font-size: 16px;
}

.tim-icons-32 {
  font-size: 32px;
}

/*----------------------------------
  add a square/circle background
-----------------------------------*/

.tim-icons-bg-square,
.tim-icons-bg-circle {
  padding: 0.35em;
}

.tim-icons-bg-circle {
  border-radius: 50%;
}

/*------------------------
  list icons
-------------------------*/

/*------------------------
  spinning icons
-------------------------*/

.tim-icons-is-spinning {
  -webkit-animation: tim-icons-spin 2s infinite linear;
  -moz-animation: tim-icons-spin 2s infinite linear;
  animation: tim-icons-spin 2s infinite linear;
}

@-webkit-keyframes tim-icons-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes tim-icons-spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}

@keyframes tim-icons-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*------------------------
  rotated/flipped icons
-------------------------*/

/*------------------------
	icons
-------------------------*/

.icon-alert-circle-exc::before {
  content: "\ea02";
}

.icon-align-center::before {
  content: "\ea03";
}

.icon-align-left-2::before {
  content: "\ea04";
}

.icon-app::before {
  content: "\ea05";
}

.icon-atom::before {
  content: "\ea06";
}

.icon-attach-87::before {
  content: "\ea07";
}

.icon-badge::before {
  content: "\ea08";
}

.icon-bag-16::before {
  content: "\ea09";
}

.icon-bank::before {
  content: "\ea0a";
}

.icon-basket-simple::before {
  content: "\ea0b";
}

.icon-bell-55::before {
  content: "\ea0c";
}

.icon-bold::before {
  content: "\ea0d";
}

.icon-book-bookmark::before {
  content: "\ea0e";
}

.icon-bulb-63::before {
  content: "\ea0f";
}

.icon-bullet-list-67::before {
  content: "\ea10";
}

.icon-bus-front-12::before {
  content: "\ea11";
}

.icon-button-pause::before {
  content: "\ea12";
}

.icon-button-power::before {
  content: "\ea13";
}

.icon-calendar-60::before {
  content: "\ea14";
}

.icon-camera-18::before {
  content: "\ea15";
}

.icon-caps-small::before {
  content: "\ea16";
}

.icon-cart::before {
  content: "\ea17";
}

.icon-chart-bar-32::before {
  content: "\ea18";
}

.icon-chart-pie-36::before {
  content: "\ea19";
}

.icon-chat-33::before {
  content: "\ea1a";
}

.icon-check-2::before {
  content: "\ea1b";
}

.icon-cloud-download-93::before {
  content: "\ea1c";
}

.icon-cloud-upload-94::before {
  content: "\ea1d";
}

.icon-coins::before {
  content: "\ea1e";
}

.icon-compass-05::before {
  content: "\ea1f";
}

.icon-controller::before {
  content: "\ea20";
}

.icon-credit-card::before {
  content: "\ea21";
}

.icon-delivery-fast::before {
  content: "\ea22";
}

.icon-double-left::before {
  content: "\ea23";
}

.icon-double-right::before {
  content: "\ea24";
}

.icon-email-85::before {
  content: "\ea25";
}

.icon-gift-2::before {
  content: "\ea26";
}

.icon-globe-2::before {
  content: "\ea27";
}

.icon-headphones::before {
  content: "\ea28";
}

.icon-heart-2::before {
  content: "\ea29";
}

.icon-html5::before {
  content: "\ea2a";
}

.icon-image-02::before {
  content: "\ea2b";
}

.icon-istanbul::before {
  content: "\ea2c";
}

.icon-key-25::before {
  content: "\ea2d";
}

.icon-laptop::before {
  content: "\ea2e";
}

.icon-light-3::before {
  content: "\ea2f";
}

.icon-link-72::before {
  content: "\ea30";
}

.icon-lock-circle::before {
  content: "\ea31";
}

.icon-map-big::before {
  content: "\ea32";
}

.icon-minimal-down::before {
  content: "\ea33";
}

.icon-minimal-left::before {
  content: "\ea34";
}

.icon-minimal-right::before {
  content: "\ea35";
}

.icon-minimal-up::before {
  content: "\ea36";
}

.icon-mobile::before {
  content: "\ea37";
}

.icon-molecule-40::before {
  content: "\ea38";
}

.icon-money-coins::before {
  content: "\ea39";
}

.icon-notes::before {
  content: "\ea3a";
}

.icon-palette::before {
  content: "\ea3b";
}

.icon-paper::before {
  content: "\ea3c";
}

.icon-pencil::before {
  content: "\ea3d";
}

.icon-pin::before {
  content: "\ea3e";
}

.icon-planet::before {
  content: "\ea3f";
}

.icon-puzzle-10::before {
  content: "\ea40";
}

.icon-satisfied::before {
  content: "\ea41";
}

.icon-scissors::before {
  content: "\ea42";
}

.icon-send::before {
  content: "\ea43";
}

.icon-settings-gear-63::before {
  content: "\ea44";
}

.icon-settings::before {
  content: "\ea45";
}

.icon-simple-add::before {
  content: "\ea46";
}

.icon-simple-delete::before {
  content: "\ea47";
}

.icon-simple-remove::before {
  content: "\ea48";
}

.icon-single-02::before {
  content: "\ea49";
}

.icon-single-copy-04::before {
  content: "\ea4a";
}

.icon-sound-wave::before {
  content: "\ea4b";
}

.icon-spaceship::before {
  content: "\ea4c";
}

.icon-square-pin::before {
  content: "\ea4d";
}

.icon-support-17::before {
  content: "\ea4e";
}

.icon-tablet-2::before {
  content: "\ea4f";
}

.icon-tag::before {
  content: "\ea50";
}

.icon-tap-02::before {
  content: "\ea51";
}

.icon-tie-bow::before {
  content: "\ea52";
}

.icon-time-alarm::before {
  content: "\ea53";
}

.icon-trash-simple::before {
  content: "\ea54";
}

.icon-triangle-right-17::before {
  content: "\ea55";
}

.icon-trophy::before {
  content: "\ea56";
}

.icon-tv-2::before {
  content: "\ea57";
}

.icon-upload::before {
  content: "\ea58";
}

.icon-user-run::before {
  content: "\ea59";
}

.icon-vector::before {
  content: "\ea5a";
}

.icon-video-66::before {
  content: "\ea5b";
}

.icon-volume-98::before {
  content: "\ea5c";
}

.icon-wallet-43::before {
  content: "\ea5d";
}

.icon-watch-time::before {
  content: "\ea5e";
}

.icon-wifi::before {
  content: "\ea5f";
}

.icon-world::before {
  content: "\ea60";
}

.icon-zoom-split::before {
  content: "\ea61";
}

.icon-refresh-01::before {
  content: "\ea62";
}

.icon-refresh-02::before {
  content: "\ea63";
}

.icon-shape-star::before {
  content: "\ea64";
}

.icon-components::before {
  content: "\ea65";
}
