.pagination {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: -33px;
  padding-top: 20px;
  justify-content: center;
  .page-item {
    padding-left: 7px;
    padding-right: 7px;
  &.disabled {
    color: #c4c4c4;
  }
  }

  .center-text,
  .center-text:hover {
    border: none;
    background: transparent;
    color: #a4a4a4;
  }
  &-arrow {
    cursor: pointer !important;
  }

}
