.preview-image-box {
  min-width: 150px;
  min-height: 150px;
  text-align: center;

  &-img {
    padding: 3px;
    width: 130px;
    height: 130px;
    border: 1px solid #e3e3e3;
    border-radius: 50%;
  }
}

.partner-content {
  &-table-avatar {
    padding: 3px;
    width: 80px;
    min-height: 80px;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
  }
}

.partner-profile-setting {
  &-password-box {
    min-height: 145px;
  }
}

.customer-appointments {
  .category {
    text-align: right;
  }
  &-table {
    .rt-th {
      text-align: left !important;
    }
  }
}

.total-appointments-box {
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: 300;
}

.remove-image {
  display: block;
  position: absolute;
  text-align: center;
  left: 58%;
  top: -18px;

  color: #fff;
  padding: 5px;
  border-radius: 50%;
  font-size: 22px;
}

.profile-information-box {

  &-info {
    display: flex;
    flex-direction: column;
  }
}