.confirm-alert-box {
    .modal-content .modal-body {
        line-height: 1.5;
        text-align: center;
        padding-bottom: 0px;
    }    
    .modal-content .modal-footer {
            border-top: 0;
            display: table-cell;
            text-align: center;
            justify-content: center
    }

    .modal-content .modal-header {
        justify-content: center;

        .modal-title {
            font-size: 16px;
            font-weight: 500;
        }
    }
    
    .modal-icon-box {
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 43px;
        .modal-icon-block {
            width: 86px;
            padding: 19px;
            font-size: 25px;
            font-weight: 100;
            border: 5px solid;
            border-radius: 50%;
        }
    }
}