.invoice-view, .single-appointment-invoice {
  margin: 0;
  font-family: "Poppins", sans-serif;
  color: #000 !important;
  background-color: #fff !important;
  .table > tbody > tr > td {
    color: #000 !important;
  }

  
  .custom-container {
    width: 100%;
    color: #333333;
    margin: 0 auto;
    overflow: hidden;
    padding: 10px 0;
    align-items: center;
    justify-content: space-around;
    display: flex;
    float: none;
    padding-top: 45px;
  }
  .item-details {
    margin-top: 40px;
  }
  .total-detail-block {
    width: 100%;
    display: flex;
    justify-content: space-between;
    &-inner {
      width: 300px;

      .item-block {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .admin-image {
    width: 100px;
  }
  .partner-image {
    width: 205px;
  }
  &-task {
    margin-top: 35px;
    color: #000;
    h3 {
      color: #000;
    }
  }
  .footer {
    margin-top: 25px;
  }

  .invoice-status-card {
    text-transform: uppercase;
    color: #FF0000;
    transform: rotate(
-30deg
);
    font-size: 29px;
    border: 2px dotted #B22222;
    padding: 14px;
    font-weight: 800;
    opacity: 0.3;
  }

  .print-invoice-btn {
    text-align: right;
    padding-top: 6px;
    margin-right: 57px;
  }
}
