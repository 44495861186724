.address-dropdown{
    position: relative;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #ffffff;
    background-clip: border-box;
    border: 0.0625rem solid rgba(34, 42, 66, 0.05);
    border-radius: 0.2857rem;

    top: 86%;
    max-width: 94%;
    width: 94%;
    right: 3%;
    border-radius: 12px;
    border: 0;
    box-shadow: 1px 2px 7px 1px RGB(0 0 0 / 13%);
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, opacity 0.3s ease 0s, height 0s linear 0.35s;
    background-color: #ffffff;
    box-sizing: border-box;
    max-height: 200px;
    position: absolute;
    z-index: 2;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 10px;
    margin-bottom: unset;
    margin-top: unset;

    &::before{
        border-bottom: 11px solid #f1eae0;
        top: -11px;
        border-left: 11px solid transparent;
        border-right: 11px solid transparent;
        content: "";
        display: inline-block;
        position: absolute;
        right: 15px;
    }
}

.address-dropdown-list{
    margin: 10px 0;
    max-height: 180px;
    overflow-y: auto;
    padding-bottom: unset;
    padding-top: unset;
    position: relative;
}

.address-dropdown-item{
    font-size: 0.8571em;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    margin-top: 5px;
    transition: all 150ms linear;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    display: block;
    outline: none;
    color: #292b2c;
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    &:hover{
        background-color: #f5f5f5;
    }
}

.customer-address.address-dropdown{
    position: relative;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, 0%);
}