.preview-image-box {
  min-width: 150px;
  min-height: 150px;
  text-align: center;
  cursor: pointer;

  &-img {
    min-width: 130px;
    height: 130px;
    border: 1px solid #2b3553 !important;
    border-radius: 0% !important;
    padding: 6px !important;
    width: auto !important;
  }
}

.partner-content {
  &-table-avatar {
    padding: 3px;
    width: 80px;
    min-height: 80px;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
  }
}

.partner-profile-setting {
  &-password-box {
    min-height: 145px;
  }
}

.partner-profile {
  .nav-container {
    text-align: left;
    margin-bottom: -7px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1);
    border: 2px solid #ffff;
    background-color: #fff;
    .nav-icons {
      padding: 0px !important;
      > li {
        display: inline-block;
        padding: 10px 6px 6px 6px;
        border-right: 1px solid #f5f5f5;
      }
    }
  }
}

.contact-profile-image-text {
  text-align: center;
  font-size: 11px;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: -11px;
}

.partner-overview {
  .remove-logo-close {
    margin-left: 10px;
    display: inline-block;
    text-align: center;
    color: #ec250d !important;
    padding: 10px 12px 10px 11px;
    border-radius: 50%;
    font-size: 13px;
    background: #3b5998;
    background-image: linear-gradient(to bottom left, #3b5998, #1e2e4f, #3b5998) !important;
  }
}
