$text-primary-color: #2c6cf6;
$text-info-color: #11bfe3;


/*
*============================= Buttons================================
*=================================================================================
*/

$button-primary-background-color: #f09b00;


/*
*=============================Alert box (alertify)================================
*=================================================================================
*/
$alert-success: #5CB811;
$alert-error: #FE1A00;
$alert-text: #fff;
$alert-box-shadow: -1px -1px 0 rgba(0,0,0,.5)
