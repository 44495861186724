.chosen-option {
  box-shadow: 0px 13px 20px -5px rgba(40, 40, 40, 0.4);

  h4 {
    font-size: 16px;
  }

  p {
    font-size: 14px;
  }
}

.assignment-type-card {
  border-radius: 15px;
  cursor: pointer;
  width: 40%;
}

.schedule-daytime-left {
  border-right: 2px solid grey;
}

.slot-min-width {
  min-width: 100px;
}
.schedule-daytime-heading {
  margin-top: -4px;
}

.validate-address-loading {
  height: 1.5rem !important;
  width: 1.5rem !important;
}

.appointments {
  .card .card-header {
    display: flex;
    justify-content: space-between;
  }
  .add-button {
    margin-top: -30px;
    button {
      margin-bottom: 13px;
    }
  }
  .card-user {
    &-info {
      display: flex;
      flex-direction: column;
    }
  }

  .create-new-customer {
    margin-top: 20px;
  }
  .separator {
    &-mini {
      width: 20px;
    }
    margin-top: 31px;
    padding-left: 24px;
    color: #c4c4c4;
  }

  .no-user-text {
    padding-left: 10%;
    padding-right: 10%;
    color: #b4b4b4;
  }
  &-table {
    margin-top: 100px;
  }

  &-deadline-text {
    font-size: 9px;
  }

  .table tr {
    text-transform: capitalize;
  }

  &-customer-profile-image {
    width: 125px;
    border: 1px solid #d4d4d4;
  }

  &-customer-name {
    font-size: 31px;
    text-transform: capitalize;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.tab-content .tab-pane {
  color: #838383 !important;
}

.bg-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  visibility: hidden;
  opacity: 0;
  transition: margin-top 0.25s ease;
  z-index: 1050;

  &.show {
    opacity: 0.85;
    visibility: visible;
  }
  &.hide {
    visibility: hidden;
  }
}

.group-list-items {
  display: flex;
  justify-content: space-between;

  .close {
    font-size: 28px;
    margin-top: 5px;
    margin-right: 4px;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.8) !important;
  }
}

.appointment-show-modal {
  margin-top: -155px !important;
  max-width: 1350px !important;
  .modal-content {
    background: #ffffff !important;
  }
  &-blocks {
    border: 1px solid rgba(8, 8, 8, 0.5);
    border-radius: 14px;
    padding: 13px;

    &.right {
      margin-left: -6px;
    }
    &.left {
      margin-left: 6px;
    }
  }
  .nav-pills .nav-item .nav-link {
    padding: 11px 15px !important;
    margin-bottom: 3px !important;
  }
  .close-modal {
    position: absolute;
    top: -22px;
    right: -11px;
    font-size: 24px;
  }

  .rating-block {
    display: flex;
    justify-content: space-between;
  }

  .accept-icon {
    width: 3.375rem;
    .accept-text {
      font-size: 8px;
    }
  }
}

.appointment-notification {
  .additional-reason {
    font-size: 12px;
  }

  .notification-heading {
    margin-bottom: 0px !important;
  }

  .notification-block {
    height: 500px;
    overflow-y: scroll;
    padding-right: 11px !important;
  }

  .card-testimonial {
    background: #ffffff !important;
  }

  .card.card-timeline .timeline > li.timeline-inverted > .timeline-panel {
    float: left !important;
  }

  .card.card-timeline .timeline.timeline-simple > li > .timeline-panel {
    padding: 11px !important ;
    width: 100% !important;
    background: #ffffff !important;
    margin-bottom: 10px !important;
  }
  .card.card-timeline .timeline > li {
    margin-bottom: 0px !important;
  }
}

.appointment-invoice {
  display: flex !important;
  font-size: 12px !important;
  font-weight: 300 !important;
  word-spacing: 1px;
  border: 1px solid rgba(0, 0, 0, 0.5) !important;
}

.appointment-professional-detail {
  background: #ffffff !important;

  .card-testimonial {
    background: #ffffff !important;
  }
  .category {
    color: rgba(0, 0, 0, 0.6) !important;
  }
  .rating-text {
    font-size: 11px;
  }

  .no-found-pro-text {
    text-align: center;
    margin-bottom: 6px;
  }

  .pro-heading {
    padding-top: 8px;
    text-align: center;
  }

  .card .card-body {
    padding: 2px;
  }

  .card-testimonial .card-header-avatar {
    height: 70px;
    width: 70px;
    margin: auto;
    padding: 0;
    display: flex;
    // max-width: 87px !important;
    // max-height: 60px !important;
  }

  .card-testimonial .card-description + .card-title {
    margin-top: 5px;
  }
  .card {
    margin: 0 !important;
    padding: 15px 0;
    gap: 15px;
  }
}

.appointment-re-booking {
  .current-job-text {
    font-size: 12px;
  }
  .rdtTime .rdtCount,
  .rdtTime .rdtSwitch {
    color: #344675;
  }
}

.appointment-notes {
  .card.card-timeline .timeline-heading {
    margin-bottom: 8px;
  }
  .card.card-timeline .timeline {
    padding: 2px 0 20px !important;

    > li:before {
      display: none !important;
    }
  }

  .input-group-append {
    border-radius: 0px;
    border-left: 1px solid rgba(0, 0, 0, 0.5);
    .input-group-text {
      padding-left: 13px !important;
    }
  }

  .input-group .form-control {
    border-left: 1px solid #2b3553 !important;
  }
  .card.card-timeline .timeline.timeline-simple > li > .timeline-panel {
    width: 100% !important;
    background: #cfcfcf !important;
  }
  .card .card-body {
    padding-right: 6px !important;
    padding-top: 1px;
    overflow-y: scroll;
    &.height {
      &-collapse {
        height: 190px;
      }
      &-expand {
        height: 300px;
      }
    }
  }

  .card.card-timeline
    .timeline
    > li.timeline-inverted
    > .timeline-panel:before {
    display: none !important;
  }
  .card.card-timeline .timeline > li {
    margin-bottom: 0px !important;

    > .timeline-panel {
      padding: 8px !important;
    }
  }

  .card.card-timeline .timeline h6 {
    color: rgba(0, 0, 0, 0.8);
    font-weight: 400;
    margin: 5px 0px 0px;
  }

  .add-note .input-group {
    textarea {
      border-color: rgba(0, 0, 0, 0.5) !important;
    }
    &-text {
      border-color: rgba(0, 0, 0, 0.5) !important;
    }

    i {
      color: #000;
    }
  }
}

.appointment-info {
  .item-block {
    display: flex;
    justify-content: space-between;
  }

  &-header {
    display: flex;
    padding-bottom: 10px;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .edit {
    margin-top: -5px;
    background: #2577f6;
    padding: 4px;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
  }
  .location-block {
    text-align: right;
  }

  .view-option-btn {
    font-size: 9px;
    padding: 5px 7px 5px 7px !important;
  }
}
.badge {
  color: #fff !important;
  padding-left: 0 !important;
  &.cancelled, &.invoice_expired {
    color: #fd5d93 !important;
  }

  &.created, &.invoice_created {
    color: #1d8cf8 !important;
  }

  &.pro_assigned {
    color: #ffff80 !important;
    background: black;
    padding: 0.25rem 0.5rem !important;
  }

  &.completed, &.invoice_paid, &.invoice_completed {
    color: #00bf9a !important;
  }

  &.invoice_pending {
    color: orange !important;
    background: black;
    padding: 0.25rem 0.5rem !important;
  }

  &.postponed, &.on-the-way  {
    color: white !important;
    background: black;
    padding: 0.25rem 0.5rem !important;
  }
}

.status-badge-block {
  margin-top: -20px;
}

.left-border {
  border: 2px solid #191f31;
}

.appointment-option-block {
  display: flex;
  justify-content: space-between;
}

.appointment-info-modal-box {
  .modal-header .close {
    right: 9px !important;
    top: 9px !important;

    > span:not(.sr-only) {
      font-size: 2.5rem !important;
      font-weight: 300;
    }
  }

  h5.modal-title {
    border-bottom: 1px solid #d4d4d4;
    padding-bottom: 8px;
    width: 100%;
  }
}

.customer-info-block {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 14px;
  padding: 13px;

  .item-block {
    justify-content: flex-start;
  }
}

.latest-notification {
  background: #5a5a5a;
  padding-top: 7px;
  margin-bottom: 10px;
}

.upcoming-notification-count {
  position: absolute;
  background: linear-gradient(to bottom left, #fd5d93, #ec250d, #fd5d93);
  border: 1px solid #d4d4d4;
  border-radius: 50%;
  padding-top: 3px;
  font-size: 9px;
  padding-left: 7px;
  padding-right: 7px;
  padding-bottom: 3px;
}

.partner-contacts-remove-image {
  display: block;
  position: absolute;
  text-align: center;
  left: 57%;
  background-color: #2F3E64;
  top: 23px;
  color: #fff;
  padding: 1px 8px 1px 7px;
  border-radius: 50%;
  font-size: 13px;
  border: 1px solid #fff;
}
