@import "../../../assets/sass/themes.scss";

.forgot-password>.content,
.request-password-reset>.content,
.lock-page>.content {
    padding-top: 22vh;
}

.forgot-password .card,
.request-password-reset .card {
    box-shadow: 0 25px 30px -13px rgba(40, 40, 40, 0.4);
    border-radius: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.forgot-password .card.card-hidden,
.request-password-reset .card.card-hidden {
    opacity: 0;
    -webkit-transform: translate3d(0, -60px, 0);
    -moz-transform: translate3d(0, -60px, 0);
    -o-transform: translate3d(0, -60px, 0);
    -ms-transform: translate3d(0, -60px, 0);
    transform: translate3d(0, -60px, 0);
}

.forgot-password .card .header,
.request-password-reset .card .header {
    padding-bottom: 20px;
}

.request-password-reset .card .btn-wd {
    min-width: 160px;
}

.forgot-password .card-footer-text {
    color: #444;
    font-weight: 100;
    font-size: 12px;
}


/* Chrome, Safari, Edge, Opera */

.forgot-password input::-webkit-outer-spin-button,
.forgot-password input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Firefox */

.forgot-password input[type=number] {
    -moz-appearance: textfield;
}

.forgot-password-text,
.resend-email-text {
    color: $text-info-color;
    font-weight: 700;
    cursor: pointer;
}

.forgot-password-text {
    text-align: right;
}

.error {
    color: #fb404b;
}

.footer-link {
    color: #1d8cf8 !important;
    cursor: pointer;
}

.request-password-reset {

    .footer-buttons {
        display: flex;
        float: right;
    }

    .card-subtitle {
        color: #000;
    }

}
.full-page.login > .content {
    padding-bottom: 130px !important;
    padding-top: 130px !important;
}

.login-card {
    outline: 30px solid #FED900;
    border-radius: 0 !important;

    .login-card-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 20px !important;

        .zoofy-logo-img {
            position: unset !important;
            margin-top: 5rem;
            margin-bottom: 1rem;
            width: 35%;
          }
      }

      .card-title {
        font-size: 1rem !important;
        color: black !important;
        text-transform: unset;
        font-weight: 400 !important;
      }
}