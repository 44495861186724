.sub-users {
  .profile-img {
    padding: 1px;
    border: 1px solid #e4e4e4;
    width: 80px;
  }

  &-head-box {
    h3 {
      padding-bottom: 10px;
    }
    display: flex;
    justify-content: space-between;
    
  }
  .add-button  {
    margin-top: -30px;
    button {
    margin-bottom: 13px;
  }
  }
 &-active-status {
  color: #a5dc86;
 }
 &-in-active-status {
  color: #b94a48;
 }

}
