@import "~alertifyjs/build/css/alertify.css";
@import "./themes.scss";
@import "~react-alice-carousel/lib/scss/alice-carousel.scss";

@media (min-width: 992px){
    .typo-line{
        padding-left: 140px;
        margin-bottom: 40px;
        position: relative;
    }

    .typo-line .category{
        transform: translateY(-50%);
        top: 50%;
        left: 0px;
        position: absolute;
    }
}

.all-icons [class^="pe-"]{
    font-size: 40px;
}
.all-icons input{
    border: 0;
}
.all-icons .font-icon-detail{
    text-align: center;
    padding: 45px 0px 30px;
    border: 1px solid #e5e5e5;
    border-radius: 6px;
    margin: 15px 0;
}
.all-icons .font-icon-container a{
    padding: 5px 0;
    display: block;
    color: #666666;
}
.all-icons .font-icon-detail input{
    margin: 25px auto 0;
    width: 100%;
    text-align: center;
    display: block;
    color: #aaa;
    font-size: 13px;
}
.all-icons h5{
    margin-top: 60px;
}
.all-icons > div > .row:first-child h5{
    margin-top: 15px;
}

.places-buttons .btn{
    margin-bottom: 30px
}

.buttons-with-margin .btn{
    margin: 5px;
}

.buttons-with-margin .btn-group .btn{
    margin: 5px 0px 0px 0px;
}

.animated .img-rounded{
    width: 150px;
    height: 150px;
    overflow: hidden;
    margin: 0 auto;
    display: block;
}
.animated .img-rounded img{
    width: 100%;
}
.heart {
    color: #EB5E28;
    animation: heathing 1s ease infinite;
}

@keyframes heathing{
    0% {
        transform: scale(0.75);
    }
    20% {
        transform: scale(1);
    }
    40% {
        transform: scale(0.75);
    }
    60% {
        transform: scale(1);
    }
    80% {
        transform: scale(0.75);
    }
    100% {
        transform: scale(0.75);
    }
}

.flex {
    display: flex;
}

.justify-content {
    &-center {
        justify-content: center;
    }

    &-space-between {
        justify-content: space-between;
    }
}

/*
*=============================Alert box (alertify) START================================
*=================================================================================
*/
.ajs-message {
    padding: 15px;
    border-radius: 4px;
    color: $alert-text;
    text-shadow:$alert-box-shadow;
}


.alertify-notifier .ajs-message.ajs-error {
    background: $alert-error;
}

.alertify-notifier .ajs-message.ajs-success {
    background: $alert-success;
}

/*
*=============================Alert box (alertify) END================================
*=================================================================================
*/


/*
*=============================Navbar START================================
*=================================================================================
*/

.navbar.navbar-inverse 
 {
    .navbar-header {
        float: none; 
        .navbar-brand {
            display: block;
            text-align:center;
            float: none;
        }
    }
}
 /*
*=============================Navbar END================================
*=================================================================================
*/


/*
*=============================Footer START================================
*=================================================================================
*/

.footer {
    &-auth {
        width: 100%;
        justify-content: space-between;
        color: #fff;
        padding-left: 48px;
        padding-right: 48px;
        padding-bottom: 8px;
    }
}
 /*
*=============================Footer END================================
*=================================================================================
*/

 /*
*=============================Padding start================================
*=================================================================================
*/

.padding {
    &-0 {
        padding: 0px !important;
    }
    &-5 {
        padding: 5px !important;
    }
    &-25 {
        padding: 25px !important;
    }
    &-50 {
        padding: 50px !important;
    }
    &-75 {
        padding: 75px !important;
    }
    &-100 {
        padding: 100px !important;
    }
    &-top {
        &-0 {
            padding-top: 0px !important;
        }
        &-25 {
            padding-top: 25px !important;
        }
    }
}
 /*
*=============================Padding end================================
*=================================================================================
*/

.space {
    &-25 {
        width: 100%;
        height: 25px;
    }
    &-50 {
        width: 100%;
        height: 50px;
    }
    &-85 {
        width: 100%;
        height: 85px;
    }
    &-100 {
        width: 100%;
        height: 100px;
    }
 }

/*
*=============================modern Cards  start================================
*=================================================================================
*/
 .card-title-box {
    color: #FFF;
    margin: 0 15px;
    padding: 0;
    position: relative;

    .icon {
        float: left;
    padding: 15px;
    margin-top: -20px;
    margin-right: 15px;
    border-radius: 3px;
    // background-color: #999;
    background: linear-gradient(60deg, #ffa726, #fb8c00);
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(255, 152, 0,.4);
    } 
    .title {
        color: #3C4858;
    margin-top: 15px;
    padding-top: 10px;
    min-height: auto;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 300;
    margin-bottom: 0px;
    text-decoration: none;
    }
 }

 /*
*=============================modern Cards  end================================
*=================================================================================
*/


.sidebar-background {
    background: url("../../assets/img/full-screen-image-2.jpg") no-repeat center center fixed;
}

#nav-with-icons-tab-overview {
    color: #f09b00;
}


.input-group-prepend.disabled {
    background-color: #E3E3E3;
    cursor: not-allowed;
}

.css-1g6gooi {
    color: #1d8cf8 !important;
}

.card-description {
    text-align: center !important;
}

.list-group-item {
    margin-top: 11px !important;
    padding: 1rem 1rem;
    background-color: #27293d !important;
    border-radius: 40px !important;
    border: 0.0625rem solid rgba(255, 255, 255, 0.8) !important;
    
}

.list-group-item + .list-group-item {
    border-top-width: unset !important;
}


.rdtPicker .dow, .rdtPicker th.rdtSwitch, .rdtPicker th.rdtNext, .rdtPicker th.rdtPrev, .rdtPicker .rdtTimeToggle {
    color: #1d8cf8 !important;
}

.modal .form-control {
    color: #777 !important;
}

.nav-pills-info {
    background: #27293d;
    margin-bottom: 7px !important;
    padding-left: 8px !important;

    li.nav-item {
        padding-top: 5px;
        cursor: pointer;
    }
}

.scrollbar
{
	margin-left: 30px;
	float: left;
	height: 300px;
	width: 65px;
	background: #F5F5F5;
	overflow-y: scroll;
	margin-bottom: 25px;
}

/*
 *  STYLE 4
 */

 .style-4::-webkit-scrollbar-track
 {
     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
     background-color: #1B1D2B;
     border-radius: 6px;
 }
 
 .style-4::-webkit-scrollbar
 {
     width: 12px;
     background-color: #1B1D2B;
     border: 1px solid #344675;
     border-radius: 6px;
 }
 
 .style-4::-webkit-scrollbar-thumb
 {
     background-color: #344675;
     border: 2px solid #344675;
     border-radius: 6px;
 }
 
 .sidebar .sidebar-wrapper {
    overflow: hidden !important;
 } 

 .sweet-alert  {
     left:30% !important;
     toP:35% !important;
 }


 .btn {
    padding: 9px 30px !important;
 }

 .navbar-minimize button {
    margin-left: -7px !important;
}

.navbar-minimize-fixed {
    margin-left: 23px !important;
}

.loader {
    text-align: center;
    font-size: 39px;
}

.alice-carousel__stage-item {
    margin:3px !important;
    border: none;
    background: none;
    border-radius: 7px !important;
}

.alice-carousel__dots {
    display: none;
}

.dont-break-out {

    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;

}

.ticker__element {
    padding-left: 100px;
}

.navbar-photo {
  height: unset !important;
}

.nav-pills {
  background: white;
}


.text-transform {
    &-uppercase {
        text-transform: uppercase;
    }
}