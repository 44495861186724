.status-text {
    font-size: 16px;
    font-weight: 100;
    word-spacing: 1.5px;

    &.cancelled {
        color: #fd5d93 !important;
    }

    &.posted {
        color: #1d8cf8 !important;
    }

    &.accepted {
        color: #ffff80 !important;
    }

    &.completed {
        color: #00f2c3 !important;
    }
}